import * as constants from "../constants.js";
import notifications from "../notifications";


export default {
    OnSuccess(description) {
        notifications.Success("Anunciante", description, 3000);
    },

    OnWarning(resp, description) {
        notifications.Warning("Anunciante", description);
        console.log(resp);
    },

    OnError(resp, description) {
        notifications.Error("Anunciante", description);
        console.log(resp);
    },

    OnUnauthorized(){
        window.location = "/";
    },

    GetAdvertiser: async function (idPublic) {
        let reqUri = `${constants.API_ENDPOINT_ADVERTISERS}/${idPublic}`;
        let result = await fetch(reqUri, {
                            method: constants.HTTP_METHOD_GET 
                        })
                        .then(resp => {
                            if (!resp.ok) {
                                if (resp.status >= 500) {
                                    this.OnError(resp, "Ocorreu uma anomalia ao carregar os Dados da Conta");
                                    return null;
                                }
                                if(resp.status == 401){
                                    this.OnUnauthorized();
                                    return null;
                                }
                            }

                            return resp.json();
                        });

        return result;
    },
    
    CreateCompanyAdvertiser: async function (formData, toShow) {
        let reqUri = `${constants.API_ENDPOINT_PUBLISHER}/company`;
        let result = await fetch(reqUri, { 
                            method: constants.HTTP_METHOD_POST,
                            headers: constants.HTTP_HEADERS_JSON,
                            body: JSON.stringify(formData)
                        })
                        .then(async resp => {
                            var jsonResult = await resp.json();
                            if (!resp.ok) {
                                if (resp.status >= 500) {
                                    this.OnError(resp);
                                    return null;
                                } else if(resp.status == 401){
                                    this.OnUnauthorized();
                                    return null;
                                } else if (resp.status == 400) {
                                    if(toShow){
                                        if (jsonResult != null && jsonResult.Errors != null && jsonResult.Errors.length > 0){
                                            let validationErrors = jsonResult.Errors.filter(e => e.Nature == 'v');
                                            if (validationErrors != null && validationErrors.length > 0){
                                                this.OnWarning(resp, "Preencha os campos marcados com *");
                                            }
                                            else
                                            {
                                                this.OnWarning(resp, jsonResult.Errors[0].Message);
                                            }
                                        }
                                    }
                                    
                                }
                            } else {
                                if (resp.status == 201) {
                                    this.OnSuccess("Utilizador criado com sucesso");
                                }
                            }

                            return jsonResult;
                        });

        return result;
    },
    
    CreatePersonalAdvertiser: async function (formData, toShow) {
        let reqUri = `${constants.API_ENDPOINT_PUBLISHER}/personal`;
        let result = await fetch(reqUri, { 
                            method: constants.HTTP_METHOD_POST,
                            headers: constants.HTTP_HEADERS_JSON,
                            body: JSON.stringify(formData)
                        })
                        .then(async resp => {
                            var jsonResult = await resp.json();
                            if (!resp.ok) {
                                if (resp.status >= 500) {
                                    this.OnError(resp);
                                    return null;
                                } else if(resp.status == 401){
                                    this.OnUnauthorized();
                                    return null;
                                } else if (resp.status == 400) {
                                    if (jsonResult != null && jsonResult.Errors != null && jsonResult.Errors.length > 0){
                                        if(toShow){
                                            let validationErrors = jsonResult.Errors.filter(e => e.Nature == 'v');
                                            if (validationErrors != null && validationErrors.length > 0){
                                                this.OnWarning(resp, "Preencha os campos marcados com *");
                                            }
                                            else
                                            {
                                                this.OnWarning(resp, jsonResult.Errors[0].Message);
                                            }
                                        }
                                    }
                                }
                            } else {
                                if (resp.status == 201) {
                                    this.OnSuccess("Utilizador criado com sucesso");
                                }
                            }

                            return jsonResult;
                        });

        return result;
    },
    
    UpdateAdvertiser: async function (idPublic, formData) {
        let reqUri = `${constants.API_ENDPOINT_ADVERTISERS}/${idPublic}`;
        let result = await fetch(reqUri, { 
                            method: constants.HTTP_METHOD_PUT,
                            headers: constants.HTTP_HEADERS_JSON,
                            body: JSON.stringify(formData)
                        })
                        .then(resp => {
                            if (!resp.ok) {
                                if (resp.status >= 500) {
                                    this.OnError(resp, "Ocorreu uma anomalia ao atualizar os Dados da Conta");
                                    return null;
                                } else if (resp.status == 400) {
                                    this.OnWarning(resp, "Preencha os campos marcados com *");
                                } else if(resp.status == 401){
                                    this.OnUnauthorized();
                                    return null;
                                }
                            } else {
                                if (resp.status == 200) {
                                    this.OnSuccess("Dados da Conta atualizados com sucesso");
                                }
                            }

                            return resp.json();
                        });

        return result;
    }
}
