<template>
  <section class="card">
    <div class="header">
      <h1 class="logo half-bottom-space">
        <img
          src="https://imgs.sapo.pt/sapologos/current/3c2c87aa420b025a0a6f59e9fdafd092cf7e66da9c9584425e8fa44ac2811fb7.png?r=7359090"
          alt="Auto SAPO" />
      </h1>
    </div>
    <div class="push-center not-found error-403">
      <h2> {{ message }}</h2>
      <a href="/" class="ink-button main-action">Voltar à página Inicial</a>
    </div>
  </section>
</template>
  
  
<script>
export default {
  name: "error",
  data() {
    return {};
  },
  props: {
    message: {
      type: String,
      default: ""
    }
  },
  methods: {},
  mounted() { },
};
</script>
  
  
<style lang="less" scoped>
@import "../../styles/variables.less";
@import "../../styles/lesshat.less";

.card {
  padding: 0;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  min-height: auto;
}

.header {
  background: @main-color;
  border-radius: 20px 20px 0 0;

  h1 {
    max-width: 235px;
    margin: 0.5em auto;
  }

}

h2 {
  font-family: "Poppins", sans-serif;
  font-size: 1.5em;
  color: @main-color;
  font-weight: 300;
  text-align: center;
}
.push-center {
  flex-direction: column;
  min-height: 250px;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0 2em;

  .ink-button {
    margin: 2em auto;
  }
}
</style>
  