<template>
  <section class="card">
    <div class="half-bottom-space">
      <h3 class="section-title push-left">Contas</h3>
    </div>

    <div class="column-group gutters grid-equal-height">
      <div class="all-50 small-100 tiny-100" v-for="n in 4" v-bind:key=n>
        <div class="framed">
          <ul class="unstyled">
            <li>Auto SAPO</li>
            <li>Saldo disponivel: <strong>300€</strong></li>
            <li><strong>4</strong> Anúncios ativos</li>
            <li><strong>4</strong> Anúncios expirados</li>
            <li>
              <a href="/settings/account" class="ink-button main-action"
                >Ver mais</a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "accounts",
};
</script>


<style lang="less" scoped>
@import "../../styles/variables.less";
@import "../../styles/lesshat.less";

.card {
  min-height: 600px;
  text-align: center;
}

.framed {
  padding: 0;
  height: 100%;
  > ul {
    position: relative;
    padding-bottom: 3em;
    height: 100%;
    > li {
      margin: 0 1em;
      padding: 0.5em 1em;
      border-bottom: 1px solid @second-color-light;

      @media screen and (max-width: @breakpoint-large) {
        padding: 0.5em;
      }

      &:first-child {
        margin: 0;
        margin-bottom: 0.5em;
        padding: 0.5em 1em;
        background: @second-color-light;
        font-size: 1.2em;
        color: @main-color;
        font-weight: 500;
        .far {
          font-size: 14px;
          line-height: 2em;
        }
      }
      &:nth-last-child(2),
      &:last-child {
        border: none;
      }
    }
  }

  strong {
    color: @main-color;
    font-weight: 500;
  }

  .ink-button {
    position: absolute;
    bottom: 1em;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>