
export var StatusCheck = function (response) {
    if (response.status >= 200 && response.status < 300) {
        return Promise.resolve(response.json());
    } else if (response.status >= 400 && response.status < 500) {
        return Promise.resolve(response.json());
    } else if (response.status >= 500) {
        return Promise.reject(new Error(response.statusText));
    } else {
        return Promise.reject(new Error(response.statusText))
    }
}

export function isNullOrEmpty(o) {
    return (o == undefined || o == null || o.length == 0);
}

export function GetErrorClass(errors, field) {
    let r = null;

    if (!isNullOrEmpty(errors)) {
        if (isNullOrEmpty(field)) field = "";
        for (let i = 0; i < errors.length; i++) {

            if (errors[i].Field == field) {
                if (errors[i].nature == "e") {
                    r = " validation error";
                } else {
                    r = " validation warning";
                }
            }

        }
    }

    return r;
}

export function GetErrorFocusElement(errorValidationList, errorOrderedList) {
    for (let iError = 0; iError < errorValidationList.length; iError++) {
        let errorValidation = errorValidationList[iError];

        for (let iErrorOrder = 0; iErrorOrder < errorOrderedList.length; iErrorOrder++) {
            if (errorValidation.Field === errorOrderedList[iErrorOrder].id) {
                errorOrderedList[iErrorOrder].flagged = true;
            }
        }
    }

    for (let iErrorOrder = 0; iErrorOrder < errorOrderedList.length; iErrorOrder++) {
        if (errorOrderedList[iErrorOrder].flagged == true) {
            return errorOrderedList[iErrorOrder].id;
        }
    }
}

export function FormatDate(inputDate, format) {
    if (!inputDate)
        return "";

    let fullDate = new Date(inputDate);
    let monthsAbrv = [
        "Jan",
        "Fev",
        "Mar",
        "Abr",
        "Mai",
        "Jun",
        "Jul",
        "Aug",
        "Set",
        "Out",
        "Nov",
        "Dez"
    ];

    let day = fullDate.getDate();
    if (day < 10)
        day = `0${day}`;

    let month = fullDate.getMonth() + 1; // january starts at 0
    if (month < 10)
        month = `0${month}`;

    let year = fullDate.getFullYear();
    let hour = fullDate.getHours();

    let minutes = fullDate.getMinutes();

    if (minutes < 10)
        minutes = `0${minutes}`;

    switch (format) {
        case "dd/MM/yyyy":
            return `${day}/${month}/${year}`;
        case "dd/MM/yyyy HH:mm":
            return `${day}/${month}/${year} ${hour}:${minutes}`;
        case "yyyy-MM-dd":
            return `${year}-${month}-${day}`;
        case "yyyy-MM-dd HH:mm":
            return `${year}-${month}-${day} ${hour}:${minutes}`;
        case "d MMM yyyy":
            if (month[0] == 0)
                return `${fullDate.getDay()} ${monthsAbrv[month[1]]} ${year}`;
            else
                return `${fullDate.getDay()} ${monthsAbrv[month]} ${year}`;
        case "dd/MM HH:mm":
            return `${day}/${month} ${hour}:${minutes}`;
        default:
            return null;
    }
}

export function MonthName(month) {
    let months = [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro"
    ];
    return months[month - 1];
}

export function Today(format) {
    return FormatDate(new Date(), format);
}


// Test cases
// isValidLicensePlateFormat("AB-12-CD"); // true
// isValidLicensePlateFormat("123-456-789"); // false
// isValidLicensePlateFormat("AB-C-D"); // false

export function isValidLicensePlateFormat(input) {

    const pattern = /^[A-Za-z0-9]{2}-[A-Za-z0-9]{2}-[A-Za-z0-9]{2}$/;

    return pattern.test(input);
}

// Translates fields to readable user strings
export function translateStrings(inputArray) {
    const conversionMap = {
        "TaxNumber": "NIF",
        "BillingAddress": "Morada",
        "BillingZipCode4": "Código Postal",
        "BillingZipCode3": "Código Postal",
        "BillingZipCodeCity": "Localidade",
        "IdDistrict": "Distrito",
        "ManagerMobile": "Telemóvel",
        "IdCounty": "Concelho",
        "AcceptTermsAndConditions": "Termos e Condições",
        "PublicName": "Designação Comercial",
        "BillingName": "Designação Social"

        // Add more mappings as needed
    };

    const convertedArray = inputArray.map(item => conversionMap[item] || item);

    // Remove duplicates
    const uniqueTranslatedArray = [...new Set(convertedArray)];

    return uniqueTranslatedArray;
}

export function scrollToElementId(elementId){    
    let element = document.getElementById(elementId);
    if (element){
        window.scroll({  top: element.getBoundingClientRect().top + window.scrollY - (window.outerHeight/2), behavior: "smooth" });
    }
}

export function isEmail(text){
    var re = /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
    return re.test(text);
}


export function OrderByAlphabetic(list, field) {
    return list.sort(function (a, b) {
        if (a[field] == null || b[field] == null) {
            return -1;
        }
        if (a[field].toLowerCase() < b[field].toLowerCase()) {
            return -1;
        }
        if (a[field].toLowerCase() > b[field].toLowerCase()) {
            return 1;
        }
        return 0;
    });
}
