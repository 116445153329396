import SigninComponent from './signin.vue';
import SignupComponent from './signup.vue';
import RegisterCompanyComponent from './register-company.vue';
import DetailsCompanyComponent from './details-company.vue';
import AccountsComponent from './accounts.vue';
import RegisterPersonalComponent from './register-personal.vue';
import DetailsPersonalComponent from './details-personal.vue';
import ForbiddenComponent from '../shared/Forbidden.vue';
import NotFoundComponent from '../shared/NotFound.vue';
import InternalServerErrorComponent from '../shared/InternalServerError.vue';
import BlockedComponent from './blocked.vue';

new Vue({
    el: "#account",
    components: {
        SigninComponent,
        SignupComponent,
        RegisterCompanyComponent,
        DetailsCompanyComponent,
        AccountsComponent,
        RegisterPersonalComponent,
        DetailsPersonalComponent,
        ForbiddenComponent,
        NotFoundComponent,
        InternalServerErrorComponent,
        BlockedComponent
    }
})