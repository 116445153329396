
export default {
    Success(title, description, timeout) {
        GrowlNotification.notify({
            title: title || 'Sucesso',
            description: description || 'Operação executada com sucesso',
            type: 'success',
            position: 'top-right',
            closeTimeout: timeout || 1500, 
            showProgress: true
        });
    },

    Warning(title, description) {
        GrowlNotification.notify({
            title: title || 'Atenção',
            description: description || 'Não foi possivel executar a operação',
            type: 'warning',
            position: 'top-right',
            closeTimeout: 10000,
            showProgress: true
        });
    },

    Error(title, description) {
        GrowlNotification.notify({
            title: title || 'Anómalia',
            description: description || 'Ocurreu uma anómalia ao executar a operação',
            type: 'error',
            position: 'top-right',
            closeTimeout: 10000,
            showProgress: true
        });
    },
    CloseAll() {
        GrowlNotification.closeAll();
    }
}