<template>
    <section class="card">
      <div class="header">
        <h1 class="logo half-bottom-space">
          <img
            src="https://imgs.sapo.pt/sapologos/current/3c2c87aa420b025a0a6f59e9fdafd092cf7e66da9c9584425e8fa44ac2811fb7.png?r=7359090"
            alt="Auto SAPO"
          />
        </h1>
        <h2>Gestor de Viaturas</h2>
      </div>
  
      <!-- <div class="align-center m-5">
      </div>       -->
      <div class="align-center mt-5 mb-1">
          <h4 class="align-center mb-1">Esta conta encontra-se bloqueada</h4>
          <P class="align-center mb-0">Por favor contacte o Auto SAPO</P>  
        </div>
        <div class="push-center">
          <a href="/account/signout" class="ink-button main-action mb-1">Sair</a>
          <a :href="frontEndUrl" class="ink-button mt-2">Voltar a Auto SAPO</a>
        </div>
    </section>
  </template>
  
  
  <script>
  export default {
    name: "blocked",
    data() {
      return {};
    },
    props:{
      frontEndUrl: {
        type: String,
        default: "/"
      }
    },
    methods: {},
    mounted() {},
  };
  </script>
  
  
  <style lang="less" scoped>
  @import "../../styles/variables.less";
  @import "../../styles/lesshat.less";
  
  .card {
    padding: 0;
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    min-height: auto;
  }
  
  .header {
    background: @main-color;
    border-radius: 20px 20px 0 0;
  
    h1 {
      max-width: 235px;
      margin: 0.5em auto;
    }
  
    h2 {
      font-family: "Poppins", sans-serif;
      margin: 0;
      font-size: 2em;
      color: @main-color;
      line-height: 50px;
      font-weight: 300;
      text-align: center;
      background: @second-color-25;
    }
  }
  
  h4{
    color: @main-color;
  }

  .push-center {
    flex-direction: column;
    min-height: 150px;
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 0 2em;
    .ink-button {
      display: block;
      max-width: 200px;
      margin: 1em auto;
      width: 100%;
    }
  }
  </style>
  