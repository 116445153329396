import * as constants from "../constants.js";
import notifications from "../notifications";


export default {
    OnWarning(resp, description, title) {

        if (title == null || title == undefined){
            title = "Dados de Referência";
        }

        notifications.Warning(title, description);
        console.log(resp);
    },

    OnError(resp, description) {
        notifications.Error("Dados de Referência", description);
        console.log(resp);
    },

    OnUnauthorized(){
        window.location = "/";
    },
    
    GetDistricts: async function () {
        let reqUri = `${constants.API_ENDPOINT_REFDATA}/gis/districts`;
        let result = await fetch(reqUri, { 
                            method: constants.HTTP_METHOD_GET 
                        })
                        .then(resp => {
                            if (!resp.ok) {
                                if (resp.status >= 500) {
                                    this.OnError(resp, "Ocorreu uma anomalia ao carregar os Distritos");
                                    return null;
                                }
                                if(resp.status == 401){
                                    this.OnUnauthorized();
                                    return null;
                                }
                            }

                            return resp.json();
                        });

        return result;
    },

    GetCounties: async function (idDistrict) {
        let reqUri = `${constants.API_ENDPOINT_REFDATA}/gis/districts/${idDistrict}/counties`;
        let result = await fetch(reqUri, { 
                            method: constants.HTTP_METHOD_GET 
                        })
                        .then(resp => {
                            if (!resp.ok) {
                                if (resp.status >= 500) {
                                    this.OnError(resp, "Ocorreu uma anomalia ao carregar os Concelhos");
                                    return null;
                                }
                                if(resp.status == 401){
                                    this.OnUnauthorized();
                                    return null;
                                }
                            }

                            return resp.json();
                        });

        return result;
    },

    GetVehicleBrands: async function () {
        let reqUri = `${constants.API_ENDPOINT_REFDATA}/vehicles/brands`;
        let result = await fetch(reqUri, { 
                            method: constants.HTTP_METHOD_GET 
                        })
                        .then(resp => {
                            if (!resp.ok) {
                                if (resp.status >= 500) {
                                    this.OnError(resp, "Ocorreu uma anomalia ao carregar as Marcas");
                                    return null;
                                }
                                if(resp.status == 401){
                                    this.OnUnauthorized();
                                    return null;
                                }
                            }

                            return resp.json();
                        });

        return result;
    },

    GetVehicleModels: async function (idBrand) {
        let reqUri = `${constants.API_ENDPOINT_REFDATA}/vehicles/models/${idBrand}`;
        let result = await fetch(reqUri, { 
                            method: constants.HTTP_METHOD_GET 
                        })
                        .then(resp => {
                            if (!resp.ok) {
                                if (resp.status >= 500) {
                                    this.OnError(resp, "Ocorreu uma anomalia ao carregar os Modelos");
                                    return null;
                                }
                                if(resp.status == 401){
                                    this.OnUnauthorized();
                                    return null;
                                }
                            }

                            return resp.json();
                        });

        return result;
    },

    GetVehicleVersions: async function (idBrand, idModel) {
        let reqUri = `${constants.API_ENDPOINT_REFDATA}/vehicles/versions/${idBrand}/${idModel}`;
        let result = await fetch(reqUri, { 
                            method: constants.HTTP_METHOD_GET 
                        })
                        .then(resp => {
                            if (!resp.ok) {
                                if (resp.status >= 500) {
                                    this.OnError(resp, "Ocorreu uma anomalia ao carregar as Versões");
                                    return null;
                                }
                                if(resp.status == 401){
                                    this.OnUnauthorized();
                                    return null;
                                }
                            }

                            return resp.json();
                        });

        return result;
    },

    GetVehicleVersion: async function (idBrand, idModel, idVersion) {
        let reqUri = `${constants.API_ENDPOINT_REFDATA}/vehicles/versions/${idBrand}/${idModel}/${idVersion}`;
        let result = await fetch(reqUri, { 
                            method: constants.HTTP_METHOD_GET 
                        })
                        .then(resp => {
                            if (!resp.ok) {
                                if (resp.status >= 500) {
                                    this.OnError(resp, "Ocorreu uma anomalia ao carregar a Versão");
                                    return null;
                                }
                                if(resp.status == 401){
                                    this.OnUnauthorized();
                                    return null;
                                }
                            }

                            return resp.json();
                        });

        return result;
    },
    GetVehicleBodyTypes: async function () {
        let reqUri = `${constants.API_ENDPOINT_REFDATA}/vehicles/bodytypes`;
        let result = await fetch(reqUri, { 
                            method: constants.HTTP_METHOD_GET 
                        })
                        .then(resp => {
                            if (!resp.ok) {
                                if (resp.status >= 500) {
                                    this.OnError(resp, "Ocorreu uma anomalia ao carregar as Carroçarias");
                                    return null;
                                }
                                if(resp.status == 401){
                                    this.OnUnauthorized();
                                    return null;
                                }
                            }

                            return resp.json();
                        });

        return result;
    },

    GetVehicleFuels: async function () {
        let reqUri = `${constants.API_ENDPOINT_REFDATA}/vehicles/fuels`;
        let result = await fetch(reqUri, { 
                            method: constants.HTTP_METHOD_GET 
                        })
                        .then(resp => {
                            if (!resp.ok) {
                                if (resp.status >= 500) {
                                    this.OnError(resp, "Ocorreu uma anomalia ao carregar os Combustíveis");
                                    return null;
                                }
                                if(resp.status == 401){
                                    this.OnUnauthorized();
                                    return null;
                                }
                            }

                            return resp.json();
                        });

        return result;
    },

    GetVehicleTransmissions: async function () {
        let reqUri = `${constants.API_ENDPOINT_REFDATA}/vehicles/transmissions`;
        let result = await fetch(reqUri, { 
                            method: constants.HTTP_METHOD_GET 
                        })
                        .then(resp => {
                            if (!resp.ok) {
                                if (resp.status >= 500) {
                                    this.OnError(resp, "Ocorreu uma anomalia ao carregar as Transmissões");
                                    return null;
                                }
                                if(resp.status == 401){
                                    this.OnUnauthorized();
                                    return null;
                                }
                            }

                            return resp.json();
                        });

        return result;
    },

    GetVehicleColors: async function () {
        let reqUri = `${constants.API_ENDPOINT_REFDATA}/vehicles/colors`;
        let result = await fetch(reqUri, { 
                            method: constants.HTTP_METHOD_GET 
                        })
                        .then(resp => {
                            if (!resp.ok) {
                                if (resp.status >= 500) {
                                    this.OnError(resp, "Ocorreu uma anomalia ao carregar as Cores");
                                    return null;
                                }
                                if(resp.status == 401){
                                    this.OnUnauthorized();
                                    return null;
                                }
                            }

                            return resp.json();
                        });

        return result;
    },

    GetVehicleDoorCount: async function () {
        let reqUri = `${constants.API_ENDPOINT_REFDATA}/vehicles/doors`;
        let result = await fetch(reqUri, { 
                            method: constants.HTTP_METHOD_GET 
                        })
                        .then(resp => {
                            if (!resp.ok) {
                                if (resp.status >= 500) {
                                    this.OnError(resp, "Ocorreu uma anomalia ao carregar as Portas");
                                    return null;
                                }
                                if(resp.status == 401){
                                    this.OnUnauthorized();
                                    return null;
                                }
                            }

                            return resp.json();
                        });

        return result;
    },

    GetVehicleByPlate: async function (fullPlate) {
        let reqUri = `${constants.API_ENDPOINT_REFDATA}/vehicles/plate/${fullPlate}`;
        let result = await fetch(reqUri, { 
                            method: constants.HTTP_METHOD_GET 
                        })
                        .then(resp => {
                            if (!resp.ok) {
                                if (resp.status >= 500) {
                                    this.OnError(resp, "Ocorreu uma anomalia ao verificar a Matrícula");
                                    return null;
                                } else if (resp.status == 404) {
                                    this.OnWarning(resp, "Por favor, preencha a informação relativa à mesma.", "A viatura não foi encontrada na nossa BD.");
                                    return null;
                                } else if(resp.status == 401){
                                    this.OnUnauthorized();
                                    return null;
                                }
                            }

                            return resp.json();
                        });

        return result;
    },

    GetVehicleByVin: async function (vin) {
        let reqUri = `${constants.API_ENDPOINT_REFDATA}/vehicles/vin/${vin}`;
        let result = await fetch(reqUri, { 
                            method: constants.HTTP_METHOD_GET 
                        })
                        .then(resp => {
                            if (!resp.ok) {
                                if (resp.status >= 500) {
                                    this.OnError(resp, "Ocorreu uma anomalia ao verificar o VIN");
                                    return null;
                                } else if (resp.status == 404) {
                                    this.OnWarning(resp, "Por favor, preencha a informação relativa à mesma.", "A viatura não foi encontrada na nossa BD.");
                                    return null;
                                } else if(resp.status == 401){
                                    this.OnUnauthorized();
                                    return null;
                                }
                            }

                            return resp.json();
                        });

        return result;
    },

    GetVehicleByPlateAndVin: async function (fullPlate, vin) {
        let reqUri = `${constants.API_ENDPOINT_REFDATA}/vehicles/plate/${fullPlate}/vin/${vin}`;
        let result = await fetch(reqUri, { 
                            method: constants.HTTP_METHOD_GET 
                        })
                        .then(resp => {
                            if (!resp.ok) {
                                if (resp.status >= 500) {
                                    this.OnError(resp, "Ocorreu uma anomalia ao verificar a matrícula e o VIN");
                                    return null;
                                } else if (resp.status == 404) {
                                    this.OnWarning(resp, "Por favor, preencha a informação relativa à mesma.", "A viatura não foi encontrada na nossa BD.");
                                    return null;
                                } else if(resp.status == 401){
                                    this.OnUnauthorized();
                                    return null;
                                }
                            }

                            return resp.json();
                        });

        return result;
    },

    GetVehicleById: async function (idVehicle) {
        let reqUri = `${constants.API_ENDPOINT_REFDATA}/vehicles/${idVehicle}`;
        let result = await fetch(reqUri, { 
                            method: constants.HTTP_METHOD_GET 
                        })
                        .then(resp => {
                            if (!resp.ok) {
                                if (resp.status >= 500) {
                                    this.OnError(resp, "Ocorreu uma anomalia ao carregar o Veículo");
                                    return null;
                                }
                                if(resp.status == 401){
                                    this.OnUnauthorized();
                                    return null;
                                }
                            }

                            return resp.json();
                        });

        return result;
    },

    CreateVehicle: async function (formData) {
        let reqUri = `${constants.API_ENDPOINT_REFDATA}/vehicles`;
        let result = await fetch(reqUri, { 
                            method: constants.HTTP_METHOD_POST,
                            headers: constants.HTTP_HEADERS_JSON,
                            body: JSON.stringify(formData)
                        })
                        .then(resp => {
                            if (!resp.ok) {
                                if (resp.status >= 500) {
                                    this.OnError(resp, "Ocorreu uma anomalia ao criar o Veículo");
                                    return null;
                                } else if(resp.status == 401){
                                    this.OnUnauthorized();
                                    return null;
                                } else if (resp.status == 400) {
                                    this.OnWarning(resp, "Preencha os campos marcados com *");
                                }
                            }

                            return resp.json();
                        });

        return result;
    },
    UpdateVehicle: async function (formData) {
        let reqUri = `${constants.API_ENDPOINT_REFDATA}/vehicles`;
        let result = await fetch(reqUri, { 
                            method: constants.HTTP_METHOD_PUT,
                            headers: constants.HTTP_HEADERS_JSON,
                            body: JSON.stringify(formData)
                        })
                        .then(resp => {
                            if (!resp.ok) {
                                if (resp.status >= 500) {
                                    this.OnError(resp, "Ocorreu uma anomalia ao alterar o Veículo");
                                    return null;
                                } else if(resp.status == 401){
                                    this.OnUnauthorized();
                                    return null;
                                } else if (resp.status == 400) {
                                    this.OnWarning(resp, "Preencha os campos marcados com *");
                                }
                            }

                            return resp.json();
                        });

        return result;
    },
    CreateNewVehicleRequest: async function (formData) {
        let reqUri = `${constants.API_ENDPOINT_REFDATA}/vehicles/newvehiclerequest`;
        let result = await fetch(reqUri, { 
                            method: constants.HTTP_METHOD_POST,
                            headers: constants.HTTP_HEADERS_JSON,
                            body: JSON.stringify(formData)
                        })
                        .then(resp => {
                            if (!resp.ok) {
                                if (resp.status >= 500) {
                                    this.OnError(resp, "Ocorreu uma anomalia ao criar o Veículo");
                                    return null;
                                } else if(resp.status == 401){
                                    this.OnUnauthorized();
                                    return null;
                                } else if (resp.status == 400) {
                                    this.OnWarning(resp, "Preencha os campos marcados com *");
                                }
                            }

                            return resp.json();
                        });

        return result;
    },
    
    UpdateVehicle: async function (formData) {
        let reqUri = `${constants.API_ENDPOINT_REFDATA}/vehicles`;
        let result = await fetch(reqUri, { 
                            method: constants.HTTP_METHOD_PUT,
                            headers: constants.HTTP_HEADERS_JSON,
                            body: JSON.stringify(formData)
                        })
                        .then(resp => {
                            if (!resp.ok) {
                                if (resp.status >= 500) {
                                    this.OnError(resp, "Ocorreu uma anomalia ao alterar o Veículo");
                                    return null;
                                } else if(resp.status == 401){
                                    this.OnUnauthorized();
                                    return null;
                                } else if (resp.status == 400) {
                                    this.OnWarning(resp, "Preencha os campos marcados com *");
                                }
                            }

                            return resp.json();
                        });

        return result;
    },
    GetEquipments: async function () {
        let reqUri = `${constants.API_ENDPOINT_REFDATA}/vehicles/equipments`;
        let result = await fetch(reqUri, { 
                            method: constants.HTTP_METHOD_GET 
                        })
                        .then(resp => {
                            if (!resp.ok) {
                                if (resp.status >= 500) {
                                    this.OnError(resp, "Ocorreu uma anomalia ao carregar os equipamentos");
                                    return null;
                                }
                                if(resp.status == 401){
                                    this.OnUnauthorized();
                                    return null;
                                }
                            }

                            return resp.json();
                        });

        return result;
    },
    
    GetEquipmentGroups: async function () {
        let reqUri = `${constants.API_ENDPOINT_REFDATA}/vehicles/equipmentgroups`;
        let result = await fetch(reqUri, { 
                            method: constants.HTTP_METHOD_GET 
                        })
                        .then(resp => {
                            if (!resp.ok) {
                                if (resp.status >= 500) {
                                    this.OnError(resp, "Ocorreu uma anomalia ao carregar os grupos de equipamentos");
                                    return null;
                                }
                                if(resp.status == 401){
                                    this.OnUnauthorized();
                                    return null;
                                }
                            }

                            return resp.json();
                        });

        return result;
    },
    
    GetEquipmentGroupsEquipments: async function (idEquipmentGroup) {
        let reqUri = `${constants.API_ENDPOINT_REFDATA}/vehicles/equipmentgroups/${idEquipmentGroup}`;
        let result = await fetch(reqUri, { 
                            method: constants.HTTP_METHOD_GET 
                        })
                        .then(resp => {
                            if (!resp.ok) {
                                if (resp.status >= 500) {
                                    this.OnError(resp, "Ocorreu uma anomalia ao carregar os grupos de equipamentos");
                                    return null;
                                }
                                if(resp.status == 401){
                                    this.OnUnauthorized();
                                    return null;
                                }
                            }

                            return resp.json();
                        });

        return result;
    },
    GetVehicleEquipments: async function (idVehicle) {
        let reqUri = `${constants.API_ENDPOINT_REFDATA}/vehicles/${idVehicle}/equipments`;
        let result = await fetch(reqUri, { 
                            method: constants.HTTP_METHOD_GET 
                        })
                        .then(resp => {
                            if (!resp.ok) {
                                if (resp.status >= 500) {
                                    this.OnError(resp, "Ocorreu uma anomalia ao carregar os equipamentos do veículo");
                                    return null;
                                }
                                if(resp.status == 401){
                                    this.OnUnauthorized();
                                    return null;
                                }
                            }

                            return resp.json();
                        });

        return result;
    },
    SaveVehicleEquipments: async function (idVehicle, equipments) {
        let reqUri = `${constants.API_ENDPOINT_REFDATA}/vehicles/${idVehicle}/equipments`;
        let result = await fetch(reqUri, { 
                            method: constants.HTTP_METHOD_POST ,
                            headers: constants.HTTP_HEADERS_JSON,
                            body: JSON.stringify(equipments)
                        })
                        .then(resp => {
                            if (!resp.ok) {
                                if (resp.status >= 500) {
                                    this.OnError(resp, "Ocorreu uma anomalia ao salvar os equipamentos");
                                    return null;
                                }
                                if(resp.status == 401){
                                    this.OnUnauthorized();
                                    return null;
                                }
                            }

                            return resp.json();
                        });

        return result;
    },
}