//TODO refactoring para estas chamadas ficam no services.JS respectivos

export default {
    methods: {
        async LoadDistricts() {
            try {
                const response = await fetch('/api/refdata/gis/district');
                const kv = await response.json();
                return kv;
            } catch (e) {
                console.log(e);
            }
        },
        async LoadCounties(idDistrict) {
            try {
                const response = await fetch('/api/refdata/gis/district/' + idDistrict + '/county');
                const kv = response.json();
                return kv;
            } catch (e) {
                console.log(e);
            }
        }
    }
}