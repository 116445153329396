<template>
    <p class="tip error" :style="getStyle()">
        <span v-for="e in ErrorList" v-bind:key="e">{{ e.Message }}<br /></span>
    </p>
</template>

<script>
    export default {
        name: "error-for",
        props: {
            field: {
                type: String,
                default: "",
            },
            fields: {
                type: Array,
                default: [""],
            },
            errors: {
                type: Array,
                default: [],
            },
            fieldValue: {
                type: String,
                default: "",
            },
            fieldsValue: {
                type: Array,
                default: [""],
            },
            showOnlyOneError: false,
            flex: false
        },
        computed: {
            ErrorList: function () {
                let r = new Array();

                if (this.errors != null && this.errors.length > 0) {
                    for (let i = 0; i < this.errors.length; i++) {
                        if (this.field != null && this.field != ""){
                            if (this.errors[i].Field == this.field) {
                                r.push(this.errors[i]);
                            }
                        } else if (this.fields){
                            if (this.fields.includes(this.errors[i].Field)) {
                                r.push(this.errors[i]);
                                if (this.showOnlyOneError){
                                    return r;
                                }
                            }
                        }
                    }
                }

                return r;
            }
        },
        watch:{
            fieldValue: function(newValue, oldValue){
                if (this.errors == null || this.errors.length == 0) return;
                
                if (newValue != oldValue){
                    this.removeFieldErrors();
                }
            },
            fieldsValue: function(newValues, oldValues){
                if (this.errors == null || this.errors.length == 0) return;
                
                for (let i = 0; i < this.fieldsValue.length; i++) {
                    let oldValue = oldValues[i];                        
                    let newValue = newValues[i];

                    if (newValue != oldValue){
                        this.removeFieldErrors();
                        return;
                    }
                }                
            }
        },
        methods: {            
            removeFieldErrors(){
                if (this.field != null && this.field != ""){
                    let errors = this.errors.filter(e => e.Field == this.field);
                    if (errors && errors.length > 0){
                        errors.forEach(error => {                        
                            let index = this.errors.indexOf(error);
                            if (index >= 0)
                                this.errors.splice(index, 1);
                        });
                    }
                }else {
                    let errors = this.errors.filter(e => this.fields.includes(e.Field));
                    if (errors && errors.length > 0){
                        errors.forEach(error => {                        
                            let index = this.errors.indexOf(error);
                            if (index >= 0)
                                this.errors.splice(index, 1);
                        });
                    }
                }
            },
            getStyle(){
                if (this.flex){
                    return "flex:1 0 100%"
                }
                return "";
            }
        }
    };
</script>
